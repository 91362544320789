import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import Logo from './logo'
import styled from 'styled-components'
import media from 'styled-media-query'

const FooterWrapper = styled.footer`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  padding-left: 2.5%;
  padding-right: 2.5%;
  background: ${(props) => (props.tech ? '' : 'var(--coffee-brown)')};
  border-top: ${(props) => (props.tech ? '' : '24px solid #EDCF8F')};
  ${media.lessThan('medium')`
    flex-direction: column;
    flex-wrap: wrap;
    padding: 36px 0;
  `};
  ${(props) =>
    props.currentPage === 'product' ? 'display: none;' : undefined};
`

const LogoWrapper = styled.div`
  grid-area: logo;
  padding: 2em 0;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  svg {
    fill: white;
    height: 100%;
    width: 100%;
    max-width: 85px;
    object-fit: contain;
    object-position: center center;
  }
  ${media.lessThan('medium')`
    margin: 0 auto;
    padding: 0;
  `};
`

const Info = styled.div`
  grid-area: info;
  margin: auto;
`

const Copyright = styled.p`
  text-align: center;
  font-weight: normal;
  font-size: 1.25em;
  line-height: 150%;
  color: white;
`

const SocialIconWrapper = styled.div`
  grid-area: social;
  color: white;
  margin: auto;
  margin-right: 0;
  ${media.lessThan('medium')`
    margin: auto;
  `};
`

const SocialIconList = styled.ul`
  display: flex;
  padding-left: 0;
`

const SocialIcon = styled.li`
  list-style: none;
  font-size: 1.5em;
  &:nth-of-type(2) {
    margin: 0 1em;
  }
  a {
    color: var(--coffee-white);
  }
`

const Email = styled.h5`
  text-align: center;
  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    font-weight: normal;
  }
`

const Addresses = styled.div`
  text-align: center;
  font-weight: normal;
  font-size: 1.25em;
  line-height: 150%;
  color: white;
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;
  font-size: 1rem;
  line-height: 1.5;
`
const Footer = ({ tech, page }) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allLandingJson {
          edges {
            node {
              address
              phone
              email
              googleMap
              weekday
              weekend
              branches {
                address
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const content = data.allLandingJson.edges[0].node
      const addresses = content.branches.map((b) =>
        b.address.replace(/\n/g, '<br />')
      )
      if (!tech) {
        return (
          <FooterWrapper tech={tech} page={page}>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <Info>
              <Copyright>
                &copy;Copyright {new Date().getFullYear()} HotBlack Coffee
              </Copyright>
              <Addresses>
                {addresses.map((address, i) => (
                  <div key={i}>
                    <span
                      key={i}
                      href={content.googlaMap}
                      dangerouslySetInnerHTML={{
                        __html: address,
                      }}
                    />
                  </div>
                ))}
              </Addresses>
              <Email>
                <a href={`mailto: ${content.email}`}>{content.email}</a>
              </Email>
            </Info>
            <SocialIconWrapper>
              <SocialIconList>
                <SocialIcon>
                  <a href="https://www.instagram.com/hotblack_coffee/">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </SocialIcon>
                <SocialIcon>
                  <a href="https://twitter.com/hotblack_coffee">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </SocialIcon>
              </SocialIconList>
            </SocialIconWrapper>
          </FooterWrapper>
        )
      }
    }}
  />
)

Footer.propTypes = {
  tech: PropTypes.bool.isRequired,
  page: PropTypes.string.isRequired,
}

Footer.defaultProps = {
  techIO: false,
}
export default Footer
