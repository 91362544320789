import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeadphones,
  faMusic,
  faShoppingBag,
} from '@fortawesome/free-solid-svg-icons'
import Link from 'gatsby-link'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Sticky from 'react-stickynode'
import uniqid from 'uniqid'

import styled from 'styled-components'
import media from 'styled-media-query'

import Logo from './logo'

const Top = styled.div`
  height: 96px;
  width: 100vw;
  padding-top: 12px;
  top: 0;
  z-index: 1;
  position: ${(props) => (props.page === 'events' ? 'absolute' : undefined)};
  ${media.lessThan('medium')`
    height: auto;
  `};
  ${(props) => (props.page === 'product' ? 'display: none;' : undefined)};
`

const NavWrapper = styled.nav`
  max-width: 100vw;
  padding-left: 2.5%;
  padding-right: 2.5%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-y: scroll;
  .active svg {
    transition-delay: 1s;
    transform: scale(0.75);
    transform-origin: left center;
  }
  ${media.lessThan('medium')`
    flex-direction: column;
    flex-wrap: wrap;
  `};
`

const LogoWrapper = styled.div`
  svg {
    width: 100px;
    transition: all 0.5s;
    height: ${(props) => (props.page === 'events' ? '100px' : '72px')};
    fill: ${(props) =>
      props.page === 'events' || props.tech === true
        ? 'var(--coffee-white)'
        : undefined};
  }
  position: relative;
  ${media.lessThan('medium')`
  display: flex;
  a {
    margin: auto;
  }
  `};
`

const MenuWrapper = styled.div`
  margin-left: auto;
  ${media.lessThan('medium')`
    margin: auto;
  `};
  a,
  li {
    color: ${(props) =>
      props.page === 'events' || props.tech === true
        ? 'var(--coffee-white)'
        : 'var(--coffee-black)'};
    &:hover {
      text-decoration: ${(props) =>
        props.tech === true ? 'underline' : 'none'};
      transition: all 0.25s;
    }
  }
`

const MenuList = styled.ul`
  margin-left: 0;
  padding-left: 0;
  display: flex;
  list-style: none;
  font-weight: bold;
  font-size: 1.25em;
  a {
    text-decoration: none;
  }
  ${media.lessThan('medium')`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  `};
`

const MenuListItem = styled.li`
  margin: 0;
  margin-left: 16px;
  cursor: pointer;
  text-transform: Capitalize;
`
const Email = styled.a``

const MenuLinkOption = (props) => (
  <MenuListItem>
    {props.anchor ? (
      <AnchorLink href={props.link}>{props.name}</AnchorLink>
    ) : (
      <Link to={props.link}>{props.name}</Link>
    )}
  </MenuListItem>
)

const Header = ({
  page = null,
  tech = null,
  jingle = null,
  techIO = null,
  jingleIO = null,
}) => (
  <StaticQuery
    query={graphql`
      query NavQuery {
        allNavigationJson {
          edges {
            node {
              index {
                logo
                menu
                store
                tech
                jingle
                return
              }
              tech {
                logo
                menu
                store
                tech
                jingle
                return
              }
              events {
                logo
                menu
                store
                tech
                jingle
                return
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const index = data.allNavigationJson.edges[0].node.index
      const secret = data.allNavigationJson.edges[0].node.tech
      const events = data.allNavigationJson.edges[0].node.events
      let current

      if (page === 'main' && !tech) {
        current = index
      } else if (page === 'main' && tech) {
        current = secret
      } else {
        current = events
      }

      return (
        <Top id="top" page={page}>
          <NavWrapper>
            <LogoWrapper page={page} tech={tech}>
              {current.logo ? (
                <AnchorLink href="#top" alt="hotblack coffee logo">
                  <Logo />
                </AnchorLink>
              ) : (
                <Link to="/">
                  <Logo />
                </Link>
              )}
            </LogoWrapper>
            <MenuWrapper page={page} tech={tech}>
              <MenuList>
                <MenuListItem>
                  <Email href={`mailto: info@hotblack-coffee.com`}>Email</Email>
                </MenuListItem>
                {current.menu.map((item) => (
                  <MenuLinkOption
                    key={uniqid()}
                    anchor={true}
                    link={`#${item}`}
                    name={item}
                  />
                ))}
                {current.store && (
                  <MenuListItem>
                    <a href="https://store.hotblack-coffee.com">Store</a>
                  </MenuListItem>
                )}
                {current.tech && (
                  <MenuListItem onClick={techIO}>
                    <span>{tech ? 'Return' : 'Tech'}</span>
                  </MenuListItem>
                )}
                {current.jingle && (
                  <MenuListItem onClick={jingleIO}>
                    {jingle ? (
                      <FontAwesomeIcon icon={faHeadphones} />
                    ) : (
                      <FontAwesomeIcon icon={faMusic} />
                    )}
                  </MenuListItem>
                )}
              </MenuList>
            </MenuWrapper>
          </NavWrapper>
        </Top>
      )
    }}
  />
)

Header.propTypes = {
  jingle: PropTypes.bool.isRequired,
  tech: PropTypes.bool.isRequired,
  techIO: PropTypes.func.isRequired,
  jingleIO: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
}
export default Header
