import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

const SectionWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 48px;
  padding-top: 48px;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 36px;
`

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  background-color: var(--flirt-alert);
  border-radius: 12px;
  padding: 0 8px;
  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
  `};
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
`

const Content = styled.div`
  padding: 1.5rem 1.25em;
  display: flex;
`

const Card = styled.div`
  height: auto;
  min-height: 100px;
  background: #edcf8f;
  border-radius: 12px;
  padding-top: 48%;
  padding-left: 20px;
  padding-bottom: 24px;
  span {
    margin-top: 0;
    margin-bottom: auto;
  }
  transition: background-image 0.5s ease-in;
  position: relative;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(54, 45, 38, 1) 100%
  );
  z-index: 1;
  &::before {
    border-radius: 12px;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      211deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(54, 45, 38, 1) 100%
    );
    z-index: -1;
    transition: opacity 0.5s linear;
    opacity: 0;
  }
  &:hover::before {
    opacity: 1;
  }
`

const Title = styled.div`
  color: white;
  font-size: 3rem;
  h3 {
    margin-top: 12px;
    margin-bottom: 0;
  }
  ${media.lessThan('medium')`
  font-size: 2rem;
  `};
`
const CardLink = styled.a`
  width: 100%;
  font-weight: bold;
  font-size: 2rem;
  text-decoration: none;
  color: var(--coffee-cream);
`

const GiftCardSection = () => (
  <SectionWrapper>
    <Box>
      <Content>
        <Title>
          <h3>Gift Cards!</h3>
        </Title>
      </Content>
      <Content>
        <CardLink
          href="https://squareup.com/gift/HWXRSQSVV0QQP/order"
          target="_blank"
        >
          <Card>
            <span>
              1921
              <br />
              Yonge Street
              <br />
              Branch
            </span>
          </Card>
        </CardLink>
      </Content>
      <Content>
        <CardLink
          href="
          https://squareup.com/gift/ZFSV4FC0AN5S9/order"
          target="_blank"
        >
          <Card>
            <span>
              245
              <br />
              Queen Street West
              <br />
              Branch
            </span>
          </Card>
        </CardLink>
      </Content>
    </Box>
  </SectionWrapper>
)

export default GiftCardSection
