import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import uniqid from 'uniqid'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import posed, { PoseGroup } from 'react-pose'

const SectionWrapper = styled.section`
  padding: 2em 10% 0;
  ${media.lessThan('medium')`
  padding-left: 0;
  padding-right: 0;
    height: auto;
  `};
`

const SelectedStaff = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
  grid-template-areas: 'image text';
  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
    grid-template-areas: 'image' 'text';
  `};
`

const SelectedStaffInfo = styled.div`
  margin: auto 0;
  padding: 1em 2em 0;
  grid-area: text;
`

const SelectedStaffName = styled.h3`
  font-size: 4em;
  margin: 0;
`
const SelectedStaffTitle = styled.h5`
  font-size: 2em;
  margin: 0;
`
const SelectedStaffBiography = styled.p`
  font-size: 1.5em;
`

const Fade = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
})

const SelectedStaffImage = styled(Fade)`
  min-height: 450px;
  grid-area: image;
  display: flex;
  img {
    margin: auto;
    max-height: 450px;
    height: 100%;
    width: 100%;
    object-position: center center;
    object-fit: contain;
    transform-origin: center bottom;
  }
  ${media.lessThan('medium')`
    padding: 0 5%;
  `};
`

const Thumbnails = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  ${media.lessThan('medium')`
    flex-direction: column;
    flex-wrap: wrap;
  `};
`

const CurrentList = styled.ul`
  margin-top: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 0;
  cursor: pointer;
  justify-content: space-between;
  &::after {
    content: '';
    flex: auto;
  }
  ${media.lessThan('medium')`
    margin-bottom: 48px;
    padding: 0 5%;
    flex-wrap: wrap;
  `};
`

const AlumniList = styled.ul`
  margin-top: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 0;
  cursor: pointer;
  position: relative;
  &::after {
    content: '';
    flex: auto;
  }
  &::before {
    content: 'Alumni:';
    position: absolute;
    font-weight: bold;
    opacity: 0;
    right: 10px;
    top: 0;
    ${media.lessThan('medium')`
      padding: 0 5%;
      opacity: 1;
      top: -28px;
      left: 10px;
    `};
  }
  &:hover {
    &::before {
      transition: all 0.2s;
      opacity: 1;
      top: -28px;
    }
  }
  ${media.lessThan('medium')`
    padding: 0 5%;
    flex-wrap: wrap;
    justify-content: space-around;
  `};
`

const Current = styled.li`
  max-width: 100px;
  list-style: none;
  margin: 0 10px;
  ${media.lessThan('medium')`
    max-width: 15%;
  `};
  img {
    max-width: 50px;
    transition: transform 0.5s;
    transform: scale(1);
    width: 100%;
    ${media.lessThan('medium')`
      max-width: 100%;
    `};
  }
  &:hover {
    img {
      transform: scale(1.5) rotate(5deg);
    }
  }
  ${media.lessThan('medium')`
    margin-bottom: 12px;
    img {
      transform: scale(1);
    }
  `};
`

const Alumni = styled.div`
  position: relative;
  list-style: none;
  margin: 0 4px;
  display: flex;
  img {
    margin: auto;
    max-width: 40px;
    transition: transform 0.5s;
    transform: scale(1);
    width: 75%;
  }
  &:hover {
    img {
      transform: scale(1.875) rotate(5deg);
    }
  }
  ${media.lessThan('medium')`
    margin-bottom: 12px;
    img {
      max-width: 100%;
      transform: scale(1);
    }
  `};
`

const StaffSection = ({ tech }) => {
  let [selected, setSelected] = useState(0)
  return (
    <StaticQuery
      query={graphql`
        query StaffQuery {
          allStaffJson {
            edges {
              node {
                staff {
                  name
                  title
                  description
                  image {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                  thumbnail {
                    childImageSharp {
                      fluid(maxWidth: 75, maxHeight: 75) {
                        src
                      }
                    }
                  }
                  alumni
                }
              }
            }
          }
        }
      `}
      render={data => {
        const staffs = data.allStaffJson.edges[0].node.staff
        if (!tech) {
          return (
            <SectionWrapper>
              <SelectedStaff>
                <PoseGroup>
                  <SelectedStaffImage key={uniqid()}>
                    <img
                      src={staffs[selected].image.childImageSharp.fluid.src}
                      alt={staffs[selected].name}
                    />
                  </SelectedStaffImage>
                </PoseGroup>
                <SelectedStaffInfo>
                  <SelectedStaffName>{staffs[selected].name}</SelectedStaffName>
                  <SelectedStaffTitle>
                    {staffs[selected].title}
                  </SelectedStaffTitle>
                  <SelectedStaffBiography>
                    {staffs[selected].description}
                  </SelectedStaffBiography>
                </SelectedStaffInfo>
              </SelectedStaff>

              <Thumbnails>
                <CurrentList>
                  {staffs.map(
                    (staff, index) =>
                      !staff.alumni && (
                        <Current
                          key={uniqid()}
                          onClick={() => setSelected((selected = index))}
                        >
                          <img
                            src={staff.thumbnail.childImageSharp.fluid.src}
                            alt={staff.name}
                          />
                        </Current>
                      )
                  )}
                </CurrentList>
                <AlumniList>
                  {staffs.map(
                    (staff, index) =>
                      staff.alumni && (
                        <Alumni
                          key={uniqid()}
                          onClick={() => setSelected((selected = index))}
                        >
                          <img
                            src={staff.thumbnail.childImageSharp.fluid.src}
                            alt={staff.name}
                          />
                        </Alumni>
                      )
                  )}
                </AlumniList>
              </Thumbnails>
            </SectionWrapper>
          )
        }
      }}
    />
  )
}

StaffSection.propTypes = {
  tech: PropTypes.bool.isRequired,
}

export default StaffSection
