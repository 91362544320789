import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

const Box = styled.div`
  background-color: #362d26;
  padding: 12px 0;
  text-align: center;
  color: white;
  line-height: 166%;
  border-bottom: 5px solid #edcf8f;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 72rem;
  margin: 0 auto;
  ${media.lessThan('medium')`
    grid-template-columns: 1fr 1fr;
  `};
`

const ContentTop = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0.5rem 0;
`
const ContentBottom = styled.div`
  font-size: 0.875rem;
`

const ContentAddress = styled.div``

const ContentHours = styled.div`
  font-size: 0.875rem;
  line-height: 1.5;
`

const Notification = () => (
  <Box>
    <ContentTop>We're Open!</ContentTop>
    <Content>
      <div>
        <ContentAddress>
          <p>
            245 Queen St W, Toronto
            <br />
            ON M5V 1Z4
          </p>
        </ContentAddress>
        <ContentHours>
          <p>
            M-F 7am-7pm
            <br />
            S/S 8am-7pm
          </p>
        </ContentHours>
      </div>
      <div>
        <ContentAddress>
          <p>
            1921 Yonge St, Toronto
            <br />
            ON M4S 1Z8
          </p>
        </ContentAddress>
        <ContentHours>
          <p>
            M-F 7am-3pm
            <br />
            S/S 8am-4pm
          </p>
        </ContentHours>
      </div>
      <div>
        <ContentAddress>
          <p>
            2196 Bloor St W,Toronto
            <br />
            ON M6S 1N4
          </p>
        </ContentAddress>
        <ContentHours>
          <p>
            M-T 7am-7pm
            <br />
            W-F 7am-9pm
            <br />
            S/S 8am-9pm
          </p>
        </ContentHours>
      </div>
      <div>
        <ContentAddress>
          <p>
            15 Charles St E
            <br />
            ON M4Y 1S1
          </p>
        </ContentAddress>
        <ContentHours>
          <p>
            M-F 7am-6pm
            <br />
            S/S 8am-6pm
          </p>
        </ContentHours>
      </div>
    </Content>
    <ContentBottom>
      All holiday hours are the same as our weekend hours
    </ContentBottom>
  </Box>
)

export default Notification
