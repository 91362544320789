import React, { Component } from 'react'
import Layout from '../components/Layout'
import Landing from '../sections/landing'
import GiftCardSection from '../sections/giftcard'
import AboutSection from '../sections/about'
import BannerSection from '../sections/banner'
import StaffSection from '../sections/staff'
import EventsSection from '../sections/events'
import CharitySection from '../sections/charity'
import PressSection from '../sections/press'

class IndexPage extends Component {
  state = {
    tech: false,
    jingle: false,
  }

  techIO = () => {
    this.setState((prevState) => ({
      tech: !prevState.tech,
    }))
  }

  jingleIO = () => {
    this.setState((prevState) => ({
      jingle: !prevState.jingle,
    }))
  }

  render() {
    const { tech, jingle } = this.state
    return (
      <Layout
        page={'main'}
        tech={tech}
        jingle={jingle}
        techIO={this.techIO}
        jingleIO={this.jingleIO}
      >
        <Landing jingle={jingle} tech={tech} />
        <BannerSection tech={tech} />
        {!tech && <GiftCardSection />}
        <AboutSection tech={tech} />
        {/* <StaffSection tech={tech} /> */}
        <CharitySection tech={tech} />
        <EventsSection tech={tech} />
        <PressSection tech={tech} />
      </Layout>
    )
  }
}

export default IndexPage
