import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'

import Notification from '../components/Notification'

import HarmoniaSansRegularEOT from './webfonts/33F324_6_0.eot'
import HarmoniaSansRegularTTF from './webfonts/33F324_6_0.ttf'
import HarmoniaSansRegularWOFF from './webfonts/33F324_6_0.woff'
import HarmoniaSansRegularWOFF2 from './webfonts/33F324_6_0.woff2'
import HarmoniaSansBoldEOT from './webfonts/33F324_A_0.eot'
import HarmoniaSansBoldTTF from './webfonts/33F324_A_0.ttf'
import HarmoniaSansBoldWOFF from './webfonts/33F324_A_0.woff'
import HarmoniaSansBoldWOFF2 from './webfonts/33F324_A_0.woff2'

import { createGlobalStyle } from 'styled-components'

const redirect = `<!--[if IE]><script type="text/javascript">window.location = "http://www.google.com/";</script><![endif]-->`

const GlobalStyle = createGlobalStyle`

html,
body {
   margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Harmonia Sans';
  src: url(${HarmoniaSansBoldEOT});
  src: url(${HarmoniaSansBoldEOT}) format('embedded-opentype'),
    url(${HarmoniaSansBoldWOFF2}) format('woff2'),
    url(${HarmoniaSansBoldWOFF}) format('woff'),
    url(${HarmoniaSansBoldTTF}) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Harmonia Sans';
  src: url(${HarmoniaSansRegularEOT});
  src: url(${HarmoniaSansRegularEOT}) format('embedded-opentype'),
    url(${HarmoniaSansRegularWOFF2}) format('woff2'),
    url(${HarmoniaSansRegularWOFF}) format('woff'),
    url(${HarmoniaSansRegularTTF}) format('truetype');
  font-weight: normal;
}

* {
  font-family: 'Harmonia Sans', sans-serif;
}

:root {
  --flirt-alert: #db1b1b;
  --coffee-brown: #362d26;
  --coffee-cream: white;
  --coffee-black: black;
  --coffee-white: white;
}


html {
  transition: background 0.2s;
  background: ${props =>
    props.tech && props.page === 'main'
      ? 'linear-gradient(90deg, var(--flirt-alert) 35px, transparent 1%) center, linear-gradient(var(--flirt-alert) 35px, transparent 1%) center, #ffd8d8'
      : 'linear-gradient(90deg, var(--coffee-white) 71px, transparent 1%) center, linear-gradient(var(--coffee-white) 71px, transparent 1%) center, var(--coffee-black)'};
  background-size: ${props => (props.tech ? '36px 36px' : '72px 72px')}
}
`

const Layout = ({ children, jingle, tech, jingleIO, techIO, page }) => (
  <StaticQuery
    query={graphql`
      query MainSiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
          <GlobalStyle tech={tech} page={page} />
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[{ name: 'Hotblack Coffee', content: '' }]}
          dangerouslySetInnerHTML={{ __html: redirect }}
        >
          <html lang="en" />
        </Helmet>
        <Notification />
        <Header
          jingle={jingle}
          tech={tech}
          techIO={techIO}
          jingleIO={jingleIO}
          page={page}
        />
        <>{children}</>
        <Footer tech={tech} page={page} />
      </>
    )}
  />
)

Layout.propTypes = {
  tech: PropTypes.bool.isRequired,
  jingle: PropTypes.bool.isRequired,
  techIO: PropTypes.func.isRequired,
  jingleIO: PropTypes.func.isRequired,
}

Layout.defaultProps = {
  tech: false,
  jingle: false,
  techIO: () => false,
  jingleIO: () => false,
}

export default Layout
